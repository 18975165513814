



























































































.kiwi-statebrowser-usermenu {
    width: 100%;
    padding-bottom: 0;
    padding-top: 34px;
}

.kiwi-statebrowser-usermenu-network {
    padding: 0 0 10px 0;
    cursor: default;
}

.kiwi-statebrowser-usermenu-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
    font-weight: 800;
    font-size: 20px;
    opacity: 0.8;
    border-bottom-left-radius: 14px;
    transition: background 0.2s, opacity 0.2s;
}

.kiwi-statebrowser-usermenu-avatar {
    position: relative;
    width: 80px;
    height: 80px;
    margin: 0 auto 0.4em auto;
    font-size: 2.8em;
    transition: background 0.2s;
}

.kiwi-statebrowser-usermenu-avatar .kiwi-avatar-inner {
    border-width: 3px;
}

.kiwi-statebrowser-usermenu .kiwi-awaystatusindicator {
    position: absolute;
    top: 4px;
    right: 0;
    width: 14px;
    height: 14px;
    border: 1px solid;
}

.kiwi-statebrowser-usermenu-body {
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;
    font-size: 0.8em;
    margin-bottom: 10px;
}

.kiwi-statebrowser-usermenu-body p {
    margin-bottom: 0;
}

@media screen and (max-width: 769px) {
    .kiwi-statebrowser-usermenu-close {
        display: none;
    }
}
